"use client";

import { useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "./components/ui/card";
import ImageLightbox from "./components/ui/image-lightbox";
import Navbar from "./components/navbar";

interface Project {
  name: string;
  subtitle: string;
  description: string;
  image: string;
  carousel: string[];
}

const projects = [
  {
    name: "SnobOutlet",
    subtitle: "Global Luxury Marketplace",
    description:
      "SnobOutlet to offer a premier marketplace where you can effortlessly browse and access a wide selection of high-end clothing, bags, and accessories from renowned brands. Whether you're looking to elevate your personal style with our curated luxury collections or easily sell your pre-loved luxury items, SnobOutlet connects you with a community that values elegance and quality.",
    technical:
      "I built Snob Outlet with a two-layered microservices architecture using NestJS for the backend and React Native for the frontend. The event-driven system includes workers and a custom scraper that gathers tens of thousands of luxury products in real-time. We use Redis for caching and MongoDB for our database. Our neural search engine utilizes semantic vector (Milvus with GPUs) search to deliver personalized results, ensuring an up-to-date experience.",
    image: "/images/snoboutlet/logo.webp",
    mode: "mobile",
    carousel: [
      "/images/snoboutlet/IMG_0104 2.webp",
      "/images/snoboutlet/IMG_0106 2.webp",
      "/images/snoboutlet/IMG_0107 2.webp",
      "/images/snoboutlet/IMG_0111 2.webp",
      "/images/snoboutlet/IMG_0113 2.webp",
      "/images/snoboutlet/IMG_0115 2.webp",
    ],
  },
  {
    name: "IMMEU",
    subtitle: "EU Wide real estate portal",
    description:
      "We constructed Immeu to bring property listings from all over Europe into one seamless platform. Whether you're looking to buy or rent, you can effortlessly explore homes across different countries. With easy-to-use filters and an intuitive design, finding your perfect next home is simple and enjoyable.",
    technical:
      "Immeu is built using NestJS and PostgreSQL for a scalable backend, and NextJS with server-side rendering for a fast, responsive frontend. ElasticSearch powers our advanced search functionality, with data synchronized from PostgreSQL via Debezium ETL pipelines. We implement caching to manage heavy traffic, ensuring reliability and efficiency. This tech stack delivers a seamless property search experience across Europe.",
    image: "/images/immeu/icon.webp",
    mode: "desktop",
    carousel: [
      "/images/immeu/details.webp",
      "/images/immeu/index.webp",
      "/images/immeu/search.webp",
    ],
  },
  {
    name: "ID Dating",
    subtitle: "Global Dating App For Travelers",
    description:
      "ID Dating is innovative dating app for passionate travelers to connect effortlessly. Instead of endless swiping, you meet like-minded individuals instantly and engage in meaningful conversations. Customize your preferences to find companions who share your interests, allowing you to create unforgettable experiences together as you journey.",
    technical:
      "We built our application on the AWS cloud using a Serverless architecture to ensure rapid scalability during user surges. The backend is powered by Express.js, enabling efficient handling of requests and seamless integration with serverless services. DynamoDB serves as our NoSQL database, providing high-performance and scalable storage for managing user information and interactions. Our distributed architecture decouples services through event-driven processes, ensuring smooth and reliable operation. Additionally, we integrate with top flower providers like The Million Roses via APIs, allowing users to send flowers directly within the chat interface. This robust tech stack maintains high availability and supports meaningful connections for our users.",
    image: "/images/ichdich/id_logo.webp",
    mode: "id_custom",
    carousel: [
      "/images/ichdich/id_dating-enhanced_full.webp",
      "/images/ichdich/landing.webp",
      "/images/ichdich/web.webp",
    ],
  },
  {
    name: "WEART",
    subtitle:
      "Social Media Platform Connecting Like Minded Artists/Creators with communities - (Some Parts under NDA / In Development)",
    description:
      "WEART is a social media platform built to connect artists and creators based on relevance, fostering meaningful interactions within highly focused communities. Through a relevance-based algorithm, WEART tailors content, recommendations, and connections to each user’s artistic interests and creative style, making it easier to find like-minded individuals and communities.",
    technical: "WeArt is built using a two-layered BFF-service microservices architecture with NestJS for the backend and React Native for the mobile frontend. It employs MongoDB for data storage and Redis to cache resource-intensive APIs such as Google Places, optimizing performance. OpenSearch with embeddings is utilized for search functionality, enabling efficient and accurate query handling. The application leverages RxJS for managing asynchronous data streams and Logstash for error tracing and monitoring. This architecture ensures scalability, high performance, and reliable operations, facilitating complex data interactions and handling high-demand usage effectively.",
    image: "/images/art_app/appicon.webp",
    mode: "mobile",
    carousel: [
      "/images/art_app/create_account.webp",
      "/images/art_app/gift_chat.webp",
      "/images/art_app/landing.webp",
      "/images/art_app/recover.webp",
    ],
  },
  {
    name: "SET GROUP",
    subtitle: "WEBSITE FOR SET GROUP NYRT - Project Gone Offline",
    description:
      "Set Group NYRT is a holding company encompassing a diverse range of businesses, including real estate, construction, and investment. Our website serves as a central hub for showcasing our portfolio and providing information about our services and projects. With a clean, modern design and intuitive navigation, visitors can easily explore our offerings and learn more about our company.",
    technical:
      "The website is built using PHP and Laravel with WordPress for CMS we used MySQL for the database and hosted on GCP. It integrated with the companies Microsoft ERP system for inquires and job applications.",
    image: "/images/set/set_logop.webp",
    mode: "custom_set",
    carousel: ["/images/set/set_video.mp4"],
  },
  {
    name: "TRIMAN KFT",
    subtitle: "WEBSITE FOR TRIMAN KFT - Project Gone Offline",
    description:
      "Triman KFT is a Hungarian railway construction company dealing with specialzied tasks like the 3rd electric rail for metro lines. Or ballast building for the new chinese railway line in Hungary among others. The website is a showcase of their projects and services.",
    technical:
      "The website is built using PHP and Laravel with WordPress for CMS we used MySQL for the database and hosted on GCP. It integrated with the companies Microsoft ERP system for inquires and job applications.",
    image: "/images/triman/logo.webp",
    mode: "custom_set",
    carousel: ["/images/triman/triman_video.mp4"],
  },
  {
    name: "拓海 aka TAKUMI (Contributor)",
    subtitle:
      "Open Source Platform For Agentic Software Development (Release Soon)",
    description:
      "I am actively contributing to the Takumi project, an open-source platform for agentic software development, Takumi is designed with an IDE in mind that has native integration with modern agentic workflows to avoid branched from VSCode with a custom build Agentic System.",
    technical:
      "The system utilizes a novel latent intra-agent communication bus which allows agents to efficiently exchange information without the additional overhead and loss of details resulting from human language conversion, the model runs of groq platform for fast operation but has self hosted runner images for security reasons with currently support for NVIDIA GPUs",
    image: "/images/takumi/takumi-logo-t.webp",
    mode: "custom_tak",
    carousel: [],
  },
];

function Jumbotron() {
  return (
    <div className="paper-grid flex  bg-primary text-primary-foreground px-0 md:px-6 lg:px-8 mb-0">
      <div className="flex flex-col md:flex-row items-center justify-between max-w-7xl px-4 mx-auto space-y-4 py-4  md:space-y-0 md:py-0">
        <div className="text-center md:text-left ">
          <h1 className="text-4xl font-bold mb-4">Nagy Ervin</h1>
          <p className="text-xl">Software Development Portfolio</p>
        </div>
        <img src="/images/back.webp" className="hidden md:flex w-1/2" />
        <img
          src="/images/back_mobile.webp"
          className="flex md:hidden w-full filter shadow-xl"
        />
      </div>
    </div>
  );
}

function ProjectCard({ project, onSelect }: any) {
  const isContributor = project.name.includes("TAKUMI");
  return (
    <Card
      className="mb-8 cursor-pointer transition-shadow duration-300 bg-transparent rounded-none border-none"
      onClick={() => onSelect(project)}
    >
      <CardHeader
        className={`flex flex-row ${
          isContributor ? " flex-col md:flex-row-reverse justify-between md:space-y-0 space-y-3" : "space-x-4"
        }`}
      >
        <img
          src={project.image}
          alt={project.name}
          style={{
            width: project.image.includes("takumi") ? 300 : 50,
            height: 50,
          }}
          className="rounded-lg"
        />
        <div className="space-y-0">
          <CardTitle className="text-white font-normal uppercase m-0 p-0 my-auto">
            {project.name}
          </CardTitle>
          <CardDescription className="text-gray-500 uppercase font-bold">
            {project.subtitle}
          </CardDescription>
        </div>
      </CardHeader>
      <CardContent className="space-y-6">
        <p className="mt-2 text-muted-foreground text-gray-100">
          {project.description}
        </p>
        <ProjectCarousel images={project.carousel} mode={project.mode} />
        <h3 className="text-white text-xl m-0 p-0 font-medium">Technical </h3>
        <p className="mt-2 text-muted-foreground text-gray-100">
          {project.technical}
        </p>
        <div className="w-full bg-gray-600" style={{ height: 1 }} />
      </CardContent>
    </Card>
  );
}

function ProjectList({ projects, onSelectProject }: any) {
  return (
    <div className="grid grid-cols-1  mb-0">
      {projects.map((project: any, index: number) => (
        <ProjectCard key={index} project={project} onSelect={onSelectProject} />
      ))}
    </div>
  );
}

function ProjectCarousel({ images, mode }: any) {
  const renderRegular = (images: any) => (
    <ImageLightbox images={images}>
      <div
        className={`flex-row w-full gap-4 grid  ${
          mode == "mobile"
            ? " grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6"
            : "grid-cols-1 lg:grid-cols-2"
        }`}
      >
        {images.map((image: string, index: number) => (
          <div key={index}>
            <img
              src={image}
              alt={`Project image ${index + 1}`}
              className="rounded-sm cursor-pointer"
            />
          </div>
        ))}
      </div>
    </ImageLightbox>
  );

  const renderIdCustom = (images: any) => {
    return (
      <div className="overflow-hidden rounded-sm">
        <div className="flex w-full overflow-hidden flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 md:h-[25rem] md:overflow-y-hidden ">
          <img
            src={images[1]}
            alt="Project image 2"
            className="rounded-sm h-full"
          />
          <img
            src={images[2]}
            alt="Project image 3"
            className="rounded-sm h-full"
          />
          <img
            src={images[0]}
            alt="Project image 3"
            className="rounded-sm h-full "
          />
        </div>
        {/* <div className=" flex-row items-center justify-center bg-gray-900 p-2 mt-4 bg-opacity-25 rounded-md hidden md:flex">
          <p className="text-white text-center ">Swipe to see more</p>
          <svg
            width={33}
            height={33}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 18L15 12L9 6"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div> */}
      </div>
    );
  };

  const renderSetCustom = (images: any) => {
    return (
      <div className="flex flex-col space-y-4">
        <div className="" style={{ overflow: "hidden" }}>
          <video
            controls
            autoPlay={false}
            className="w-full -mt-[2px] rounded-lg"
          >
            <source src={images[0]} type="video/mp4" />
          </video>
        </div>
      </div>
    );
  };
  const render = (images: any) => {
    switch (mode) {
      case "id_custom":
        return renderIdCustom(images);
      case "custom_set":
        return renderSetCustom(images);
      default:
        return renderRegular(images);
    }
  };

  const isCustomMode = mode == "custom";
  return render(images);
}

export default function Portfolio() {
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);

  return (
    <div className="min-h-screen poppins-light">
      <Navbar />
      <Jumbotron />
      <main className="container mx-auto px-0 max-w-7xl mb-8">
        <ProjectList projects={projects} onSelectProject={setSelectedProject} />
        <div className="text-white px-6 space-y-3">
          <div className="">
            <h4 className="text-2xl font-bold">
              Smaller Projects and other Contributions
            </h4>
          </div>
          <ul className="pl-5 space-y-4">
            <li
              style={{
                listStyleType: "square",
              }}
            >
              <h5 className="text-lg mb-1 font-bold">AROX Trading platform</h5>
              <p>
                Proprietary High Frequency Trading platform using WPF and Java
                with some components in C++, allowing Python for backtesting and
                strategy development. It utilizes a fully event driven
                architecture with Flatbuffers and RedPanda (Kafak Written in
                C++) with KDB+ and Postgres as Database. We used MINIO for flat
                file storage and CUDA GPU accelerated backtester for the
                strategies.
              </p>
            </li>
            <li
              style={{
                listStyleType: "square",
              }}
            >
              <h5 className="text-lg mb-1 font-bold">
                DeltaForce Ingatlan Website (Changeed partialyl since I left the
                project)
              </h5>
              <p>
                Simple website constructed in NestJS with Strapi as CMS hosted
                on AWS
              </p>
            </li>
            <li
              style={{
                listStyleType: "square",
              }}
            >
              <h5 className="text-lg mb-1 font-bold">
                Hajlekom.com website (Changeed since I left the project)
              </h5>
              <p>
                Simple website constructed in NestJS with Strapi as CMS hosted
                on AWS
              </p>
            </li>
            <li
              style={{
                listStyleType: "square",
              }}
            >
              <h5 className="text-lg mb-1 font-bold">Angyalvonal.hu</h5>
              <p>
                Simple website in ReactJS for astrology readings with webcam
                feed integration
              </p>
            </li>
          </ul>
        </div>
      </main>
      <footer className="w-full bg-gray-900 text-white text-center py-4 flex justify-between px-4">
        <div className="max-w-7xl w-full flex justify-between mx-auto px-8 flex-col md:flex-row space-y-4 md:space-y-0">
          <div>
            <p>Scaffolded by Takumi finsihed by Nagy Ervin</p>
          </div>
          <div className="md:space-x-4  flex-col md:flex-row flex space-y-2 md:space-y-0">
            <a href="mailto:info@nagyervin.com" className="m-0 p-0">
              info@nagyervin.com
            </a>
            <a href="mailto:tel+36205769010" className="m-0 p-0">
              +36 20 576 9010
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}
