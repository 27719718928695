import React, { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

interface ImageLightboxProps {
  images: string[];
  children: React.ReactNode;
}

const ImageLightbox: React.FC<ImageLightboxProps> = ({ images, children }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div onClick={handleOpen}>{children}</div>
      <Lightbox
        open={open}
        close={handleClose}
        slides={images.map((src) => ({ src }))}
      />
    </>
  );
};

export default ImageLightbox;